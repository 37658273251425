



 @media screen and (min-width: 1200px) {
  
    :root{
        --fontSiztext:17px; 
        --fontSiztitle:30px; 
        --paddingBTn:12px;
    }

    hr{
        width: 2000px;
        display: none;
    }
nav{
    height: 100px;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

nav >  .bar {
    display: none;
}

nav > .title {
    font-size: var(--fontSiztitle);
    margin-left: 40px;
    font-weight: 800;
}

nav > div > ul {
    list-style: none;
   
}

nav > div > ul > li {
    margin-inline: 20px;
    font-size: var(--fontSiztext);
    color: rgb(24, 23, 23);
    font-weight: 500;
    display: inline-block;

}



nav > div > ul > li > .linkbtn {
    text-decoration: none;
    color:black;
    display: block;
    text-align: center;
}

nav > div > ul > li > .linkbtn:hover{
    /* color: red; */
    color: rgb(146, 145, 145);
}


nav > div > ul > li >.servicestag > .drop {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
   
}


nav > div > ul > li >.servicestag:hover{
    color:  rgb(146, 145, 145);
}


nav > div > ul > li > div.dropdown{
    display: none;
    height: auto;
    width: auto;
    background-color:transparent;
    border-color:transparent;
    position: absolute;
    /* top: -10px; */
    z-index: 999;
    transition: display 0.6s ease-in-out;

    /* padding: 20px 90px 20px 30px; */
}

nav > div > ul > li > div > ul {
    list-style: none;
    padding-right:50px ;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
}

nav > div > ul > li:hover {
    color: rgb(150, 149, 149);
}

nav > div > ul > li:hover   div.dropdown{
    display: block;	
    border-color: rgb(80, 78, 78);
    color: rgb(192, 192, 192);

}
nav > div > ul > li > div >  ul > li > .linkbtn {
    text-decoration: none;
    color:black;
}

nav > div > ul > li > div >  ul > li > .linkbtn:hover{
    color: rgb(192, 192, 192);
}

nav > div > ul > li > div >  ul {
    color: rgb(30, 30, 30);

}

nav > div > ul > li > div >  ul > li {
    margin-block: 20px;
    
}
/* nav > div > ul > li > div >  ul > li > a{
    margin-block: 50px;
    text-decoration: none;
    color: rgb(30, 30, 30);  
} */


nav > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

nav > div > ul > li >  .trackorder > .trackorderLink{
    text-decoration: none;
    color: antiquewhite;
}

nav > div > ul > li >  .trackorder {
    background-color: black;
    color: antiquewhite;
    /* padding: 20px; */
    padding: var(--paddingBTn);
    
    border-radius: 40px;
    font-size: var(--fontSiztext);
    width: 170px;
    text-align: center;
    transition: background-color 0.5s ease-in-out;
}

nav > div >ul > li > .trackorder:hover{
    background-color: orange;
}



nav > div > ul > li > .dropdown > .transparentclass {
    height: 50px;
    background-color: transparent;
 }

nav > div > ul > li > .dropdown > .orange {
    height: 6px;
    background-color: rgb(232, 153, 6);
    font-weight: 800;
 }

 li{
    cursor: pointer;
 }

 .servicestag {
    display: flex;
    justify-content: space-between;
} 

}




































@media screen and (max-width: 1200px) {


    nav{
        height: 200px;
        width: 100%;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;


    }

    nav > div {
        margin-block: 20px;
    }

    nav >  .bar {
        font-size: 30px;
       width: 45px;
       display: flex;
       position: sticky;
       justify-content: center;
       align-items: center;
        padding: 8px 0px 8px 0px;
        border: 1px solid white; 
        
         color: white;
        border-radius: 7px;

    }

    
    nav > .title {
        font-size: 50px;
        font-weight: 650;
       
    }
    
    nav > div > ul {
        list-style: none;
        font-size: 15px;
    }

    nav > .droplistparent {
        width: 100%;
        padding: 0px;
       border: 1px solid rgb(115, 114, 114);
       background-color: white;
       border-top: 0px;
       border-left: 0px;
       border-right: 0px;
       margin-top: -10px;
       z-index: 99;
    }


    nav > div > ul > li >  .trackorder > .trackorderLink{
        text-decoration: none;
        color: rgb(38, 38, 38);
    }

    nav > .droplistparent > ul > li {
        margin-right: 30px;
        
    }

    nav > .droplistparent > ul > li > a {
        text-decoration: none;
        color:black;
    }

    nav > .droplistparent > ul > li > .trackorder {
        width: 100%;
        height: 30px;
        text-align: center;
        
    }

    nav > .droplistparent > ul > li > div > ul  {
        margin-right: 30px;
        list-style: none;

    }
    nav > .droplistparent > ul > li > div > ul > li{
        margin-block: 15px;
        font-size: 15px;
    }
    nav > .droplistparent > ul > li > div > ul > li >a {

        text-decoration: none;
        color:black;
    }
 .hrNav{
        width: 3000px;
        position: relative;
        left: -300px;
    }

    li {
        cursor: pointer;
    }



    li{
        -webkit-tap-highlight-color: transparent;
    }


 .servicestag {
        display: flex;
        justify-content: space-between;
    } 

 .servicestag > .drop:hover {
   height: 16px;
    border: 1px solid black;
    border-radius: 6px; 
 }

} 