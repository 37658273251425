
@media  screen and (min-width:1200px) {

.body{
    height: var(--windowheight);
    width: var(--windowwidth);
    background-color: white;
   overflow: scroll;

}

.body::-webkit-scrollbar{
    display: none;
}




/* freightintro */

.body > .freightintro{
    background-color:rgb(96, 96, 96);
    height: auto;
    width: var(--windowwidth);
    display: flex;
    align-items: center;
    padding: 50px 0px 50px 0px ;
}


.body > .freightintro > ul {
    list-style: none;
    color: white;
    margin-inline: 250px;
    width: 35%;
}

.body > .freightintro > ul > li {
    margin-block: 10px;
}
.body > .freightintro > ul > .h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 30pt;
}
.body > .freightintro > ul > .text{
    font-size: 15px;
    line-height: 20pt;
    font-weight: 500;
    
}
.body > .freightintro > ul > .yesbtn{
    
    background-color: black;
    color: antiquewhite;
    padding: 15px;
    border-radius: 40px;
    font-size: 15px;
    width: 200px;
    text-align: center;
    transition: background-color 0.5s ease-in-out;

    
}

.body > .freightintro > ul > .yesbtn:hover{
    background-color: orange;
}





/* thisiswhy */

.body > .thisiswhy {
    height: auto;
    width: var(--windowwidth);
    display: flex;
    align-items: center;
}
.body > .thisiswhy > ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-inline: 120px;
    margin-block: 50px;

}
.body > .thisiswhy > ul > li {
   margin-block: 10px;
}
.body > .thisiswhy > ul > li.h1 {
    font-size: 30px;
    font-weight: 700;
}
.body > .thisiswhy > ul > li.h2 {
    font-size: 40px;
    font-weight: 700;
}
.body > .thisiswhy > ul > li > ul {
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 20px;
}
.body > .thisiswhy > ul > li > ul > li {
    margin: 10px;
    display: flex;
    min-height: 600px;
    background-color: white;
    border-radius: 50px;
    box-shadow:10px 10px 80px 1px rgb(237, 236, 236);
    

    
}
.body > .thisiswhy > ul > li > ul > li > ul  {
    /* padding: 5; */
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    margin-top: 100px;
}

.body > .thisiswhy > ul > li > ul > li:hover{
    box-shadow:10px 10px 80px 1px rgb(204, 203, 203);

}

.body > .thisiswhy > ul > li > ul > li > ul > li {
    margin-block: 10px;
}
.body > .thisiswhy > ul > li > ul > li > ul > li.iconthis {
   position: relative;
   bottom: 50px;
   font-size: 50px;
   color: orange;
}
.body > .thisiswhy > ul > li > ul > li > ul > li.iconthis:hover{
  color: rgb(108, 107, 107);
  font-size: 110px;
}
.body > .thisiswhy > ul > li > ul > li > ul > li.h3 {
    font-size:20px;
    font-weight: 800;
    text-align: center;
}
.body > .thisiswhy > ul > li > ul > li > ul > li.text {
    line-height: 30pt;
    color: rgb(88, 87, 87);
    text-align: center;
    margin: 0px 30px 0px 0px;
}






/* personsf */



.body > .personsf {
    height:auto;
    width: var(--windowwidth);
    background-color: white;
    display: flex;
    align-items: center;
}

.body > .personsf > ul {
    margin-inline: 100px;
    display: grid;
    grid-template-columns: 1fr  1fr;
    list-style: none;
    /* margin-block: 200px; */

}
.body > .personsf > ul > li {}
.body > .personsf > ul > li > ul {
    list-style: none;
    font-size: 15px;
    margin-inline: 10px;

}
.body > .personsf > ul > li > ul > li {
    margin-block:20px;
    margin-inline: 10px;
}
.body > .personsf > ul > li > ul > li.line {
    height: 1px;
    width: 100%;
    background-color: orange;
}
.body > .personsf > ul > li > ul > li.userp {
    display: flex;
    align-items: center;
   
}
.body > .personsf > ul > li > ul > li.userp > img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
}
.body > .personsf > ul > li > ul > li.userp > .name {
   margin-left: 20px;
}  
.body > .personsf > ul > li > ul > li.stars {
    display: flex;
    font-size: 20px;
    color: orange;
}
.body > .personsf > ul > li > ul > li.text {
    line-height: 20pt;
}










/* freeestimaate */

.body > .freeestimaate {
    height: auto;
    background-color: rgb(86, 85, 85);
    display: flex;
    align-items: center;
    justify-content: center;
}
.body > .freeestimaate > ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    width: 70%;

    
}
.body > .freeestimaate > ul > li{
    margin-block: 20px;
}
.body > .freeestimaate > ul > li.getaqoutbtn{
    background-color: black;
    color: antiquewhite;
    padding: 15px;
    border-radius: 40px;
    font-size: 15px;
    width: 120px;
    text-align: center;
    transition: background-color 0.5s ease-in-out;
}

.body > .freeestimaate > ul > li.getaqoutbtn:hover{
    background-color: orange;
    
}

.body > .freeestimaate > ul > li > ul {
    list-style: none;
}
.body > .freeestimaate > ul > li > ul > li{
    margin-block: 20px;
}
.body > .freeestimaate > ul > li > ul > li.h1{
    font-size: 25px;
    font-weight: 900;
    color: rgb(242, 207, 207);
}
.body > .freeestimaate > ul > li > ul > li.h2{
    font-size: 50px;
    font-weight: 900;
    color: white;
}

    
}












































































@media  screen and (max-width:1200px) {

    .body{
        height: var(--windowheight);
        width: var(--windowwidth);
        background-color: white;
       overflow: scroll;
    
    }
    
    .body::-webkit-scrollbar{
        display: none;
    }
    
    
    
    
    /* freightintro */
    
    .body > .freightintro{
        background-color:rgb(96, 96, 96);
        height: auto;
        /* width: var(--windowwidth); */
        display: flex;
        align-items: center;
        padding: 50px 0px 50px 0px;
    }
    
    
    .body > .freightintro > ul {
        list-style: none;
        color: white;
        padding: 0;
        margin-inline: 40px;
        width: 70%;
    }
    
    .body > .freightintro > ul > li {
        margin-block: 10px;
    }
    .body > .freightintro > ul > .h1 {
        font-size: 25px;
        font-weight: 700;
        line-height: 30px;
    }
    .body > .freightintro > ul > .text{
        font-size: 15px;
        line-height: 30px;
        font-weight: 300;
        
    }
    .body > .freightintro > ul > .yesbtn{
        
        background-color: black;
        color: antiquewhite;
        padding: 10px;
        border-radius: 40px;
        font-size: 15px;
        width: 250px;
        text-align: center;
        transition: background-color 0.5s ease-in-out;
        position: relative;
        top: 10px;
        
    }
    
    .body > .freightintro > ul > .yesbtn:hover{
        background-color: orange;
    }
    
    
    
    
    
    /* thisiswhy */
    
    .body > .thisiswhy {
        height: auto;
        width: var(--windowwidth);
        display: flex;
        align-items: center;
    }
    .body > .thisiswhy > ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-inline: 10px;
        margin-block: 20px;
        padding: 0;
    
    }
    .body > .thisiswhy > ul > li {
       margin-block: 20px;
    }
    .body > .thisiswhy > ul > li.h1 {
        font-size: 20px;
        font-weight: 700;
    }
    .body > .thisiswhy > ul > li.h2 {
        font-size: 20px;
        font-weight: 700;
    }
    .body > .thisiswhy > ul > li > ul {
        list-style: none;
        padding: 0;
        margin-top: 10px;
    }
    .body > .thisiswhy > ul > li > ul > li {
        margin: 4px;
        display: flex;
        min-height: 200px;
        background-color: white;
        padding: 20px;
        border-radius: 15px;
        box-shadow:10px 10px 80px 1px rgb(237, 236, 236);
        
    
        
    }
    .body > .thisiswhy > ul > li > ul > li > ul  {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        margin-top: 30px;
        padding: 0;
    }
    
    .body > .thisiswhy > ul > li > ul > li:hover{
        box-shadow:10px 10px 80px 1px rgb(204, 203, 203);
    
    }
    
    .body > .thisiswhy > ul > li > ul > li > ul > li {
        margin-block: 10px;
    }
    .body > .thisiswhy > ul > li > ul > li > ul > li.iconthis {
       /* position: relative;
       bottom: 100px; */
       font-size: 30px;
       color: orange;
    }
    .body > .thisiswhy > ul > li > ul > li > ul > li.iconthis:hover{
      color: rgb(108, 107, 107);
      font-size: 40px;
    }
    .body > .thisiswhy > ul > li > ul > li > ul > li.h3 {
        font-size:20px;
        font-weight: 700;
        text-align: center;
    }
    .body > .thisiswhy > ul > li > ul > li > ul > li.text {
        line-height: 30px;
        color: rgb(88, 87, 87);
        text-align: center;
        margin: 0px 30px 0px 0px;
    }
    
    
    
    
    
    
    /* personsf */
    
    .body > .personsf {
        height:auto;
        width: var(--windowwidth);
        background-color: white;
        display: flex;
        align-items: center;
    }
    
    .body > .personsf > ul {
        margin-inline: 10px;
        /* display: grid;
        grid-template-columns: 1fr  1fr; */
        list-style: none;
        margin-block: 50px;
        padding: 0;
    
    }
    .body > .personsf > ul > li {}
    .body > .personsf > ul > li > ul {
        list-style: none;
        padding: 0;
        font-size: 15px;
        margin-block:30px;
        /* margin-inline: 10px; */
    
    }
    .body > .personsf > ul > li > ul > li {
        margin-block:20px;
        margin-inline: 10px;
    }
    .body > .personsf > ul > li > ul > li.line {
        height: 1px;
        width: 90%;
        background-color: orange;
    }
    .body > .personsf > ul > li > ul > li.userp {
        display: flex;
        align-items: center;
       
    }
    .body > .personsf > ul > li > ul > li.userp > img {
        height: 70px;
        width: 70px;
        border-radius: 50%;
    }
    .body > .personsf > ul > li > ul > li.userp > .name {
       margin-left: 20px;
    }  
    .body > .personsf > ul > li > ul > li.stars {
        display: flex;
        font-size: 20px;
        color: orange;
    }
    .body > .personsf > ul > li > ul > li.text {
        line-height: 30px;
    }
    
    
    
    
    
    
    
    
    /* freeestimaate */
    
    .body > .freeestimaate {
        height: auto;
        background-color: rgb(86, 85, 85);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .body > .freeestimaate > ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        width: 90%;
        padding: 0;
    
        
    }
    .body > .freeestimaate > ul > li{
    
        margin-block: 10px;
    }
    .body > .freeestimaate > ul > li.getaqoutbtn{
        background-color: black;
        color: antiquewhite;
        padding: 10px 25px 10px 25px;
        border-radius: 40px;
        font-size: 15px;
        width: 100px;
        text-align: center;
        transition: background-color 0.5s ease-in-out;
    }
    
    .body > .freeestimaate > ul > li.getaqoutbtn:hover{
        background-color: orange;
        
    }
    
    .body > .freeestimaate > ul > li > ul {
        list-style: none;
        padding: 0;
    }
    .body > .freeestimaate > ul > li > ul > li{
        margin-block: 10px;
    }
    .body > .freeestimaate > ul > li > ul > li.h1{
        font-size: 17px;
        font-weight: 900;
        text-align: center;
        color: rgb(242, 207, 207);
    }
    .body > .freeestimaate > ul > li > ul > li.h2{
        font-size: 30px;
        font-weight: 900;
        color: white;
    }
    
        
    }
    
    